import { ValueAddedService } from '../models/ValueAddedService';

function hardCodedServices(): ValueAddedService[] {
    return [
        {
            displayName: "Oversize (>7ft)", inputType: "numeric", value: 0,
            isDangerousGoods: false, isProtectedService: false, commodityCode: '', 
            lineItemName: "OVER SIZED PIECES", overrideCode: '(', transactionCode: '', constantPieceCount: null
        },{
            displayName: "Carpet pole", inputType: "numeric", value: 0,
            isDangerousGoods: false, isProtectedService: false, commodityCode: '', 
            lineItemName: "CARPET POLE", overrideCode: '\\', transactionCode: '', constantPieceCount: null
        },{
            displayName: "Shunted trailer", inputType: "numeric", value: 0,
            isDangerousGoods: false, isProtectedService: false, commodityCode: '', 
            lineItemName: "SHUNTED TRAILER", overrideCode: '%', transactionCode: '', constantPieceCount: null
        },{
            displayName: "Appliance handling", inputType: "numeric", value: 0,
            isDangerousGoods: false, isProtectedService: false, commodityCode: '', 
            lineItemName: "APPLIANCE HANDLING", overrideCode: ')', transactionCode: '', constantPieceCount: null
        },{
            displayName: "Load securement/dunnage", inputType: "numeric", value: 0,
            isDangerousGoods: false, isProtectedService: false, commodityCode: '', 
            lineItemName: "LOAD SECUREMENT", overrideCode: '8', transactionCode: '', constantPieceCount: null
        },{
            displayName: "Shrink wrap", inputType: "numeric", value: 0,
            isDangerousGoods: false, isProtectedService: false, commodityCode: '', 
            lineItemName: "PALLET WRAP", overrideCode: '{', transactionCode: '', constantPieceCount: null
        },{
            displayName: "Pallet assembly", inputType: "numeric", value: 0,
            isDangerousGoods: false, isProtectedService: false, commodityCode: '', 
            lineItemName: "PALLET ASSEMBLY/RESTACK", overrideCode: '}', transactionCode: '', constantPieceCount: null
        },{
            displayName: "Rescale / recube", inputType: "numeric", value: 0,
            isDangerousGoods: false, isProtectedService: false, commodityCode: '', 
            lineItemName: "RE-SCALE/RE-CUBE", overrideCode: ':', transactionCode: '', constantPieceCount: null
        },{
            displayName: "Flat deck", inputType: "checkbox", value: false,
            isDangerousGoods: false, isProtectedService: false, commodityCode: 'DECK', 
            lineItemName: "FLAT DECK", overrideCode: '', transactionCode: '', constantPieceCount: 0
        },{
            displayName: "Dangerous goods", inputType: "checkbox", value: false,
            isDangerousGoods: true, isProtectedService: false, commodityCode: '', 
            lineItemName: "", overrideCode: '', transactionCode: '', constantPieceCount: null
        },{
            displayName: "Cartage delivery", inputType: "checkbox", value: false,
            isDangerousGoods: false, isProtectedService: false, commodityCode: '', 
            lineItemName: "CARTAGE DELIVERY", overrideCode: 'G', transactionCode: '', constantPieceCount: 0
        },{
            displayName: "Cartage pick up", inputType: "checkbox", value: false,
            isDangerousGoods: false, isProtectedService: false, commodityCode: '', 
            lineItemName: "CARTAGE PICKUP", overrideCode: 'G', transactionCode: '', constantPieceCount: 0
        }
    ];
}

export function getValueAddedServices(): ValueAddedService[] {

    return hardCodedServices();

}