import WorkOrder from "./../models/WorkOrder";
import { v4 as uuidv4 } from 'uuid';
import {cloneDeep} from 'lodash';
import { getValueAddedServices } from "../services/VASService";

export function createEmptyWorkOrder(): WorkOrder {
    return {

        guid: uuidv4(),
        workOrderNumber: "",
        manifestNumber: "",
        reference: "",
        
        customerNumber: "",
        customerName: "",
    
        shipperName: "",
        shipperAddress1: "",
        shipperAddress2: "",
        shipperCity: "",
        shipperProvince: "",
        shipperPostalCode: "",
    
        consigneeName: "",
        consigneeAddress1: "",
        consigneeAddress2: "",
        consigneeCity: "",
        consigneeProvince: "",
        consigneePostalCode: "",
    
        inboundCarrierNumber: "",
        inboundCarrierName: "",
        
        outboundCarrierNumber: "",
        outboundCarrierName: "",
        
        unloadDate: new Date(),
        unloadTime: new Date(),
        unloadDoor: "",
        unloadDockWorker: "",
        loadDate: null,
        loadTime: null,
        loadDoor: "",
        loadDockWorker: "",
    
        printLabels: "bol",

        description: "",
        pieces: 0,
        weight: 0,
        weightUom: "lbs",
        reWeight: 0,
        reWeightUom: "lbs",
        cube: 0,
        cubeUom: "ft",
        reCube: 0,
        reCubeUom: "ft",
    
        valueAddedServices: getValueAddedServices(),
    
        otherServiceSelected: false,
        otherServices: "",
    
        inboundNotes: "",
        outboundNotes: "",
        crossDockNotes: "",
        lastPlaced: "",
    
        driverNameInbound: "",
        driverNameOutbound: "",
        label: ""
    };
}

export function cloneWorkOrder(wo: WorkOrder): WorkOrder {
    return {

        guid: uuidv4(), // always assign a new GUID
        workOrderNumber: "", // always reset the workorder number, this will be assigned at save by the back-end
        manifestNumber: "", // always reset the manifest number, this will be assigned at save by the back-end

        reference: wo.reference,
        
        customerNumber: wo.customerNumber,
        customerName: wo.customerName,
    
        shipperName: wo.shipperName,
        shipperAddress1: wo.shipperAddress1,
        shipperAddress2: wo.shipperAddress2,
        shipperCity: wo.shipperCity,
        shipperProvince: wo.shipperProvince,
        shipperPostalCode: wo.shipperPostalCode,
    
        consigneeName: wo.consigneeName,
        consigneeAddress1: wo.consigneeAddress1,
        consigneeAddress2: wo.consigneeAddress2,
        consigneeCity: wo.consigneeCity,
        consigneeProvince: wo.consigneeProvince,
        consigneePostalCode: wo.consigneePostalCode,
    
        inboundCarrierNumber: wo.inboundCarrierNumber,
        inboundCarrierName: wo.inboundCarrierName,
        
        outboundCarrierNumber: wo.outboundCarrierNumber,
        outboundCarrierName: wo.outboundCarrierName,
        
        unloadDate: wo.unloadDate,
        unloadTime: wo.unloadTime,
        unloadDoor: wo.unloadDoor,
        unloadDockWorker: wo.unloadDockWorker,
        loadDate: wo.loadDate,
        loadTime: wo.loadTime,
        loadDoor: wo.loadDoor,
        loadDockWorker: wo.loadDockWorker,
    
        printLabels: wo.printLabels,

        description: wo.description,
        pieces: wo.pieces,
        weight: wo.weight,
        weightUom: wo.weightUom,
        reWeight: wo.reWeight,
        reWeightUom: wo.reWeightUom,
        cube: wo.cube,
        cubeUom: wo.cubeUom,
        reCube: wo.reCube,
        reCubeUom: wo.reCubeUom,
    
        valueAddedServices: cloneDeep(wo.valueAddedServices), 
    
        otherServiceSelected: wo.otherServiceSelected,
        otherServices: wo.otherServices,
    
        inboundNotes: wo.inboundNotes,
        outboundNotes: wo.outboundNotes,
        crossDockNotes: wo.crossDockNotes,
        lastPlaced: wo.lastPlaced,
    
        driverNameInbound: wo.driverNameInbound,
        driverNameOutbound: wo.driverNameOutbound,
        label: "" // always reset the label, we shall generate a new label based on the new workorder number
    };
}

export function createFullWorkOrder(): WorkOrder {
    return {

        guid: uuidv4(),
        workOrderNumber: "",
        manifestNumber: "",
        reference: "REF45378901234567890",
        
        customerNumber: "OCS",
        customerName: "TEST CUSTOMER",
    
        shipperName: "SHIPPER NAME @35 CHARACTERS MAXIMUM",
        shipperAddress1: "SHIPPER ADDRESS LINE 1 @35 CHAR MAX",
        shipperAddress2: "SHIPPER ADDRESS LINE 2 @35 CHAR MAX",
        shipperCity: "SHIPPER CITY MAX 25 CHARS",
        shipperProvince: "AB",
        shipperPostalCode: "S0S 0S0",
    
        consigneeName: "CONSIGNEE NAME  @ 35 CHARACTERS MAX",
        consigneeAddress1: "CONSIGNEE ADDRESS LINE 1 @ 35 CHARS",
        consigneeAddress2: "CONSIGNEE ADDRESS LINE 2 @ 35 CHARS",
        consigneeCity: "CONSIGNEE CITY @ 25 CHARS",
        consigneeProvince: "BC",
        consigneePostalCode: "C0C 0C0",
    
        inboundCarrierNumber: "",
        inboundCarrierName: "INBOUND CARRIER NAME MAX @ 35 CHARS",
        
        outboundCarrierNumber: "",
        outboundCarrierName: "OUTBOUND CARRIER NAME MAX @35 CHARS",
        
        unloadDate: new Date(),
        unloadTime: new Date(),
        unloadDoor: "23A",
        unloadDockWorker: "INBOUND DOCK WORKER NAME @ 35 CHARS",
        loadDate: new Date(),
        loadTime: new Date(),
        loadDoor: "32B",
        loadDockWorker: "OUTBOUND DOCK WORKER NAME @35 CHARS",
    
        printLabels: "bol",

        // split by 40 chars
        description: "The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy dog. The quick brown fox  jumps over the lazy dog. The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy dog. The  quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy  dog. The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy dog. The quick brown fox jumps.",
        pieces: 15,
        weight: 23500,
        weightUom: "lbs",
        reWeight: 23850,
        reWeightUom: "lbs",
        cube: 2300,
        cubeUom: "ft",
        reCube: 2392,
        reCubeUom: "ft",
    
        valueAddedServices: getValueAddedServices(),

        otherServiceSelected: true,
        otherServices: "Other services. The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy dog. The quick.",
    
        inboundNotes: "Inbound notes. The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy dog. The quick brown fox jumps.",
        outboundNotes: "Outbound notes. The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy dog. The quick brown fox jumps.",
        crossDockNotes: "Cross dock notes. The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy.",
        lastPlaced: "LAST PLACED LOC123 LAST PLACED LOC456 FINALLY AT LOC78965",
    
        driverNameInbound: "INBOUND DRIVER FULL NAME @ 35 CHARS",
        driverNameOutbound: "OUTBOUND DRIVER FULL NAME @35 CHARS",

        label: ""

    };

        

}