import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import Axios from 'axios';
// import { RouteComponentProps } from 'react-router';
// import { NavItem, NavLanding, ECDApp } from '@rosenau/ecd-template-components';
import { authProvider, NavItem, ECDApp } from '@rosenau/ecd-template-components';
import * as serviceWorker from './serviceWorker';

import CrossDockForm from './components/CrossDockForm';
// import LabelTestComponent from './components/LabelTestComponent';

// const AppNavLanding = (props: RouteComponentProps) => <NavLanding navigation={Navigation} {...props} />

const Navigation: NavItem = {
    path: "/",
    title: "ECD Label Printer",
    description: <p>This is the landing page for the ECD Label Printer.</p>,
    component: CrossDockForm
};

ReactDOM.render(<ECDApp navigation={Navigation} authorize={async () => {
    const token = await authProvider.getIdToken();

    await Axios.get(process.env.REACT_APP_LabelPrinterEndpointBaseURL + "/authorize", {
        headers: {
            "Authorization": `Bearer ${token.idToken.rawIdToken}`
        }
    });

    return Promise.resolve(true);
}} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
