import React from 'react';
import Axios from 'axios';
import { Form, Formik, FormikHelpers, FieldArray} from 'formik';
import scrollIntoView from 'scroll-into-view-if-needed';
import { FormSection, FormRow, FormColumn, FormInputField, FormDateField, FormTimeField, FormTextAreaField, FormSelectField, getErrorMessage, FormCityPicker, withoutEmpty, getFormikErrorsFromValidationResult } from '@rosenau/rosenau-ui';
// import ECDFormSection from "./FormSection";
import WorkOrder from "./../models/WorkOrder";
import BrowserPrint from "../zebra/BrowserPrint-2.0.0.75.min";
import { createZebraLabelForPrinting, createZebraLabelForDisplay } from "../services/LabelService";
import FormCustomerPickerField from '@rosenau/ecd-ui-customer-picker';
import FormDriverPickerField from '@rosenau/ecd-ui-driver-picker';
import { authProvider } from '@rosenau/ecd-template-components';
import { createEmptyWorkOrder, cloneWorkOrder, createFullWorkOrder } from '../models/WorkOrderFactory';
import FormValueAddedServiceInputField from './FormValueAddedServiceInputField';
import FormRequiredIfCheckedCheckboxField from './FormRequiredIfCheckedCheckboxField';
import FormValueAddedServiceCheckBoxField from './FormValueAddedServiceCheckBoxField';
import LabelImage from './LabelImage';
import SaveWorkOrderResponse from '../models/SaveWorkOrderResponse';
// import Moment from 'moment';

class InputField extends FormInputField<WorkOrder> {}
class TextAreaField extends FormTextAreaField<WorkOrder> {}
class SelectField extends FormSelectField<WorkOrder> {}
// class CheckBoxField extends FormCheckboxField<WorkOrder> {}
class CityPicker extends FormCityPicker<WorkOrder> {}
class CustomerPickerField extends FormCustomerPickerField<WorkOrder> {}
class DriverPickerField extends FormDriverPickerField<WorkOrder> {}
class ValueAddedServiceCheckBoxField extends FormValueAddedServiceCheckBoxField<WorkOrder> {}
class DateField extends FormDateField<WorkOrder> {}
class TimeField extends FormTimeField<WorkOrder> {}
class ValueAddedServiceInputField extends FormValueAddedServiceInputField<WorkOrder> {}
class RequiredIfCheckedCheckboxField extends FormRequiredIfCheckedCheckboxField<WorkOrder> {}

interface CrossDockFormProps {
}

interface CrossDockFormState {
    errorMessage?: React.ReactNode;
    formCompleteMessage?: React.ReactNode; 
    formComplete: boolean;
    formValidated: boolean;
    labelPreview: string;
    previousWorkorder: WorkOrder | null;
}

export default class CrossDockForm extends React.Component<CrossDockFormProps, CrossDockFormState> {

    // production flags
    useBackEnd: boolean = true; // TRUE: calls back-end to validate the form, assign a workorder number and save the workorder; FALSE: generate random workorder number
    usePrinter: boolean = true; // TRUE: use the Zebra label printer to print the label; FALSE: skip printing
    clearFormAfterSubmit: boolean = true; // TRUE: resets the form (except the optional label images) after successful submission

    // test flags
    isTest: boolean = false;
    showTestButtons: boolean = this.isTest; // TRUE: show "Populate", "Validate" and "Preview Label" buttons for testing
    showLabelImage: boolean = this.isTest; // TRUE: display the generated label as an image; FALSE: don't display images

    ref: React.RefObject<HTMLDivElement>;

    constructor(props: Readonly<CrossDockFormProps>) {
        super(props);

        this.state = {
            formComplete: false,
            formCompleteMessage: null,
            formValidated: false,
            errorMessage: null,
            labelPreview: '',
            previousWorkorder: null
        };

        this.ref = React.createRef();
    }

    resetState() {
        this.setState({
            formComplete: false,
            formCompleteMessage: null,
            formValidated: false,
            errorMessage: null,
            labelPreview: ''
        });
    }

    public componentDidMount() {
        this.resetState(); // force loading the service definitions
        if (this.ref.current) {
            const firstInput = this.ref.current.querySelector("input:not([readonly]), select:not([readonly]), textarea:not([readonly])");

            if (firstInput) {
                (firstInput as any).focus();
            }
        }
    }

    public componentDidUpdate() {
        if (this.ref.current) {
            if (this.state.formComplete) {
                const message = this.ref.current.querySelector(".confirmation-message");

                if (message) {
                    scrollIntoView(message, { behavior: "smooth", scrollMode: "if-needed", block: "center" });
                }

                this.setState({
                    formComplete: false
                });

            } else if (this.state.formValidated) {
                const message = this.ref.current.querySelector(".error-message, .invalid-feedback");
                
                if (message) {
                    scrollIntoView(message, { behavior: "smooth", scrollMode: "if-needed", block: "center" });
                }

                this.setState({
                    formValidated: false
                });
            }
        }
    }

    renderButtonRow(formik: any) {
        return  <FormRow className="mt-3">
                    <FormColumn columns={12}>

                        <button type="button" className="btn btn-secondary mr-2" onClick={() => {
                            this.resetState();
                            formik.resetForm({
                                values: createEmptyWorkOrder()
                            });

                            if (this.ref.current) {
                                const firstInput = this.ref.current.querySelector("input:not([readonly]), select:not([readonly]), textarea:not([readonly])");

                                if (firstInput) {
                                    (firstInput as any).focus();
                                }
                            }
                        }} disabled={formik.isSubmitting || formik.isValidating}>Reset</button>
                        
                        {this.state.previousWorkorder && <button type="button" className="btn btn-outline-secondary mr-2" onClick={() => {
                            this.resetState();
                            formik.resetForm({
                                values: this.state.previousWorkorder
                            });
                            
                            if (this.ref.current) {
                                const firstInput = this.ref.current.querySelector("input:not([readonly]), select:not([readonly]), textarea:not([readonly])");

                                if (firstInput) {
                                    (firstInput as any).focus();
                                }
                            }
                        }} disabled={formik.isSubmitting || formik.isValidating}>Clone previous</button>}
                        
                        {this.showTestButtons && <button type="button" className="btn btn-outline-secondary mr-2" onClick={() => {
                            this.resetState();
                            formik.resetForm({
                                values: createFullWorkOrder()
                            });
                            
                            if (this.ref.current) {
                                const firstInput = this.ref.current.querySelector("input:not([readonly]), select:not([readonly]), textarea:not([readonly])");

                                if (firstInput) {
                                    (firstInput as any).focus();
                                }
                            }
                        }} disabled={formik.isSubmitting || formik.isValidating}>Populate</button>}

                        <button type="submit" className="btn btn-primary float-right ml-3" disabled={formik.isSubmitting || formik.isValidating}>Save and print</button>
                        
                        {this.showTestButtons && <button type="button" className="btn btn-outline-secondary float-right ml-3" onClick={() => {
                            // generate label
                            this.setState({labelPreview: createZebraLabelForDisplay(formik.values)})
                        }} disabled={formik.isSubmitting || formik.isValidating}>Preview label</button>}

                        {this.showTestButtons && <button type="submit" className="btn btn-outline-secondary float-right ml-3" onClick={() => {
                            formik.validateForm();
                        }} disabled={formik.isSubmitting || formik.isValidating}>Validate</button>}

                        {this.showTestButtons && ((Object.keys(formik.errors).length) ?
                            <button type="button" className="btn btn-outline-danger float-right ml-3" disabled={true}>:-(</button> : 
                            <button type="button" className="btn btn-outline-success float-right ml-3" disabled={true}>:-)</button>
                        )}

                    </FormColumn>
                </FormRow>;
    }

    render() {
        
        let help = {
            customerNumber: "You can search for a customer by name or number,\nor use 881 if the customer is not registered.",
            customerName: "Use the customer number field to select a customer.",
            reference: "",
            workOrderNumber: "",
            inboundCarrierNumber: "You can search by name or customer number,\nor leave it blank if the inbound carrier is not registered\nas a customer.",
            inboundCarrierName: "Type in the name of the inbound carrier,\nor use the carrier number field to select from a list of customers.",
            outboundCarrierNumber: "You can search by name or customer number,\nor leave it blank if the inbound carrier is not registered\nas a customer (or if unknown).",
            outboundCarrierName: "Type in the name of the outbound carrier,\nor use the carrier number field to select from a list of customers.\nLeave it blank if unknown.",
            shipperName: "",
            shipperAddress1: "",
            shipperAddress2: "",
            shipperCity: "Search for a city by name, or type in the city name and province if not found.",
            shipperPostalCode: "You can type in a Canadian postal code with or without a space\n(the system will add the space and make it uppercase),\nor use the 5 digit or 5-4 digit US format.",
            consigneeName: "",
            consigneeAddress1: "",
            consigneeAddress2: "",
            consigneeCity: "Search for a city by name, or type in the city name and province if not found.",
            consigneePostalCode: "You can type in a Canadian postal code with or without a space\n(the system will add the space and make it uppercase),\nor use the 5 digit or 5-4 digit US format.",
            pieces: "",
            weight: "",
            weightUom: "",
            cube: "",
            cubeUom: "",
            printLabels: "Choose which labels to print:\n- 'BOL only' prints the BOL label only, no freight labels,\n- '1 freight' prints the BOL label plus one freight label,\n- 'All' prints the BOL label plus as many freight labels as number of pieces,\n- 'None' prints nothing.\nThere is no re-print so choose wisely. ",
            reWeight: "",
            reWeightUom: "",
            reCube: "",
            reCubeUom: "",
            description: "",
            valueAddedServicesNumber: "Number of pieces requiring the service.",
            valueAddedServicesCheckbox: "Applies to the entire freight (all pieces).",
            otherServiceSelected: "",
            otherServices: "",
            unloadDate: "",
            unloadTime: "",
            unloadDoor: "",
            unloadDockWorker: "Select from the list by name or driver number to manifest to that dock worker.\nType in the name if not found in the list (will be manifested without a dock worker ID).\nNote: dock workers are stored in the driver file (from the AS/400 main menu options 23 > 1).",
            inboundNotes: "",
            driverNameInbound: "Type in the name of the driver who dropped off the freight,\nthe name will be printed at the bottom of the label for their signature.",
            loadDate: "",
            loadTime: "",
            loadDoor: "",
            loadDockWorker: "Select from the list by name or driver number, or leave it empty if unknown.\nType in the name if not found in the list.\nNote: dock workers are stored in the driver file (from the AS/400 main menu options 23 > 1).",
            outboundNotes: "",
            driverNameOutbound: "Type in the name of the driver who will depart with the freight,\nthe name will be printed at the bottom of the label for their signature.",
            lastPlaced: "",
            crossDockNotes: ""
        }

        return <div ref={this.ref}>
            {this.state.formCompleteMessage && <div className="alert alert-success confirmation-message">{this.state.formCompleteMessage}</div>}
            {this.state.errorMessage && <div className="alert alert-danger error-message">{this.state.errorMessage}</div>}
            <Formik
                initialValues={createEmptyWorkOrder()}
                isInitialValid={false}
                onSubmit={this.submit.bind(this)}
                validateOnBlur={false}
                validateOnChange={false}
                validate={this.validate.bind(this)}
            >
            { formik =>
                <Form noValidate={true} onChange={() => {
                    this.setState({
                        formValidated: false
                    });
                }}>


                {this.renderButtonRow(formik)}
                <FormRow>

                    <FormColumn columns={(formik.values.workOrderNumber) ? 7 : 8} className="my-1">
                        <FormSection title="Customer" className="mb-0">
                            <FormRow>
                                <FormColumn columns={2}>
                                    <CustomerPickerField formik={formik} labelText="Customer #" name="customerNumber" title={help.customerNumber} required={true} onSelected={(c) => {
                                        formik.setFieldValue("customerName", c?.customerName);
                                    }}/>
                                </FormColumn>
                                <FormColumn columns={10}>
                                <InputField labelText='Customer name' name="customerName" title={help.customerName} disabled={true} required={false} maxLength={35} />
                                </FormColumn>
                            </FormRow>
                        </FormSection>
                    </FormColumn>

                    <FormColumn columns={(formik.values.workOrderNumber) ? 5 : 4} className="my-1">
                        <FormSection title="Work order" className="mb-0">
                            <FormRow>
                                <FormColumn columns={(formik.values.workOrderNumber) ? 8 : 12}>
                                    <InputField labelText="Reference" name="reference" title={help.reference} required={true} maxLength={20} />
                                </FormColumn>
                                {(formik.values.workOrderNumber) && <FormColumn columns={4}>
                                    <InputField labelText="Order #" name="workOrderNumber" title={help.workOrderNumber} disabled={true} required={false} maxLength={10} />
                                </FormColumn>}
                            </FormRow>
                        </FormSection>
                    </FormColumn>

                </FormRow>
                <FormRow>

                    <FormColumn columns={6} className="my-1">
                        <FormSection title="Inbound carrier" className="mb-0">
                            <FormRow>
                                <FormColumn columns={2}>
                                    <CustomerPickerField formik={formik} labelText="Carrier #" name="inboundCarrierNumber" title={help.inboundCarrierNumber} required={false} onSelected={(c) => {
                                        formik.setFieldValue("inboundCarrierName", c?.customerName);
                                    }}/>
                                </FormColumn>
                                <FormColumn columns={10}>
                                    <InputField labelText="Carrier name" name="inboundCarrierName" title={help.inboundCarrierName} disabled={false} required={true} maxLength={35} />
                                </FormColumn>
                            </FormRow>
                        </FormSection>  
                    </FormColumn>

                    <FormColumn columns={6} className="my-1">
                        <FormSection title="Outbound carrier" className="mb-0">
                            <FormRow>
                                <FormColumn columns={2}>
                                    <CustomerPickerField formik={formik} labelText="Carrier #" name="outboundCarrierNumber" title={help.outboundCarrierNumber} required={false} onSelected={(c) => {
                                        formik.setFieldValue("outboundCarrierName", c?.customerName);
                                    }}/>
                                </FormColumn>
                                <FormColumn columns={10}>
                                    <InputField labelText="Carrier name" name="outboundCarrierName" title={help.outboundCarrierName} disabled={false} required={false} maxLength={35} />
                                </FormColumn>
                            </FormRow>
                        </FormSection>
                    </FormColumn>

                </FormRow>
                <FormRow>

                    <FormColumn columns={6} className="my-1">
                        <FormSection title="Shipper" className="mb-0">
                            <FormRow>
                                <FormColumn columns={12}>
                                    <InputField labelText="Name" name="shipperName" title={help.shipperName} required={true} maxLength={35} />
                                </FormColumn>
                                <FormColumn columns={12}>
                                    <InputField labelText="Address" name="shipperAddress1" title={help.shipperAddress1} required={true} maxLength={35} />
                                </FormColumn>
                                <FormColumn columns={12}>
                                    <InputField labelText="" name="shipperAddress2" title={help.shipperAddress2} required={false} maxLength={35} />
                                </FormColumn>
                                <CityPicker environment={process.env.REACT_APP_Environment} formik={formik} cityField="shipperCity" provinceField="shipperProvince" title={help.shipperCity} required={true} maxLength={25} />
                                <FormColumn columns={4}>
                                    <InputField labelText="Postal code" name="shipperPostalCode" title={help.shipperPostalCode} required={false} maxLength={10} />
                                </FormColumn>
                            </FormRow>
                        </FormSection>
                    </FormColumn>

                    <FormColumn columns={6} className="my-1">
                        <FormSection title="Consignee" className="mb-0">
                            <FormRow>
                                <FormColumn columns={12}>
                                    <InputField labelText="Name" name="consigneeName" title={help.consigneeName} required={true} maxLength={35} />
                                </FormColumn>
                                <FormColumn columns={12}>
                                    <InputField labelText="Address" name="consigneeAddress1" title={help.consigneeAddress1} required={true} maxLength={35} />
                                </FormColumn>
                                <FormColumn columns={12}>
                                    <InputField labelText="" name="consigneeAddress2" title={help.consigneeAddress2} required={false} maxLength={35} />
                                </FormColumn>
                                <CityPicker environment={process.env.REACT_APP_Environment} formik={formik} cityField="consigneeCity" provinceField="consigneeProvince" title={help.consigneeCity} required={true} maxLength={25} />
                                <FormColumn columns={4}>
                                    <InputField labelText="Postal code" name="consigneePostalCode" title={help.consigneePostalCode} required={false} maxLength={10} />
                                </FormColumn>
                                {/*}
                                <FormColumn columns={12}>
                                    <InputField labelText="" name="consigneePhone" required={false} maxLength={18} />
                                </FormColumn>
                                */}
                            </FormRow>
                        </FormSection>
                    </FormColumn>
                </FormRow>

                <FormRow>

                    <FormColumn columns={8} className="my-1">
                        <FormSection title="Shipment" className="mb-0">
                            <FormRow>
                                <FormColumn columns={2}>
                                    <InputField labelText="Pieces" name="pieces" title={help.pieces} required={true} maxLength={4} />
                                </FormColumn>
                                <FormColumn columns={3}>
                                    <InputField labelText="Total weight" name="weight" title={help.weight} required={true} maxLength={5} />
                                </FormColumn>
                                <FormColumn columns={2}>
                                    <SelectField labelText="Units" name="weightUom" title={help.weightUom} required={false} options={[
                                                    { value: "lbs", description: "lbs" },
                                                    { value: "kg", description: "kg" }
                                                ]} />
                                </FormColumn>
                                <FormColumn columns={3}>
                                    <InputField labelText="Total cubic volume" name="cube" title={help.cube} required={false} maxLength={5} />
                                </FormColumn>
                                <FormColumn columns={2}>
                                    <SelectField labelText="Units" name="cubeUom" title={help.cubeUom} required={false} options={[
                                                    { value: "ft", description: "cu ft" },
                                                    { value: "in", description: "cu in" },
                                                    { value: "m", description: "cu m" },
                                                    { value: "cm", description: "cu cm" }
                                                ]} />
                                </FormColumn>
                            </FormRow>
                            <FormRow>
                                <FormColumn columns={2}>
                                    <SelectField labelText="Print labels" name="printLabels" title={help.printLabels} required={false} options={[
                                                        { value: "none", description: "None" },
                                                        { value: "bol", description: "BOL only" },
                                                        { value: "bol+one", description: "1 freight" },
                                                        { value: "all", description: "All" }
                                                    ]} />
                                </FormColumn>
                                <FormColumn columns={3}>
                                    <InputField labelText="Re-weight" name="reWeight" title={help.reWeight} required={false} maxLength={5} />
                                </FormColumn>
                                <FormColumn columns={2}>
                                    <SelectField labelText="Units" name="reWeightUom" title={help.reWeightUom} required={false} options={[
                                                    { value: "lbs", description: "lbs" },
                                                    { value: "kg", description: "kg" }
                                                ]} />
                                </FormColumn>
                                <FormColumn columns={3}>
                                    <InputField labelText="Re-cube volume" name="reCube" title={help.reCube} required={false} maxLength={5} />
                                </FormColumn>
                                <FormColumn columns={2}>
                                    <SelectField labelText="Units" name="reCubeUom" title={help.reCubeUom} required={false} options={[
                                                    { value: "ft", description: "cu ft" },
                                                    { value: "in", description: "cu in" },
                                                    { value: "m", description: "cu m" },
                                                    { value: "cm", description: "cu cm" }
                                                ]} />
                                </FormColumn> 
                            </FormRow>
                            <FormRow>
                                <FormColumn columns={12}>
                                    <TextAreaField labelText="Description" name="description" title={help.description} required={true} rows={6}  maxLength={520}/>
                                </FormColumn>
                            </FormRow>
                        </FormSection>
                    </FormColumn>

                    <FormColumn columns={4} className="my-1">
                        <FormSection title="Services" className="mb-0">
                            <FormRow>
                                <FormColumn columns={12}>
                                {(formik.values.valueAddedServices && formik.values.valueAddedServices.length > 0) && <FieldArray 
                                    name = "selectedServices"
                                    render = {_ => (
                                        <div>
                                        {formik.values.valueAddedServices.map( (service, index) => { 
                                            switch (service.inputType) {
                                                case "numeric":
                                                    return <ValueAddedServiceInputField key={index} labelText={service.displayName} name={`valueAddedServices.${index}.value`} min={0} max={formik.values.pieces} title={help.valueAddedServicesNumber} />
                                                case "checkbox":                                                    
                                                    return <ValueAddedServiceCheckBoxField key={index} labelText={service.displayName} name={`valueAddedServices.${index}.value`} title={help.valueAddedServicesCheckbox} />
                                                default:                                                    
                                                    return <div></div>
                                            }
                                        })}
                                        </div>
                                    )}
                                    />
                                }
                                <RequiredIfCheckedCheckboxField labelText="Other services" name="otherServiceSelected" title={help.otherServiceSelected} required={formik.values.otherServiceSelected} />
                                {formik.values.otherServiceSelected && <TextAreaField name="otherServices" title={help.otherServices} required={formik.values.otherServiceSelected} rows={5} maxLength={250}/>}
                                </FormColumn>
                            </FormRow>
                        </FormSection>
                    </FormColumn>
                </FormRow>

                <FormRow>

                    <FormColumn columns={6} className="my-1">
                        <FormSection title="Unloading" className="mb-0">
                            <FormRow>
                                <FormColumn columns={3}>
                                    <DateField labelText="Unload date" name="unloadDate" title={help.unloadDate} required={true} />
                                </FormColumn>
                                <FormColumn columns={3}>
                                    <TimeField labelText="Unload time" name="unloadTime" title={help.unloadTime} required={true} />
                                </FormColumn>
                                <FormColumn columns={2}>
                                    <label>&nbsp;<br/></label>
                                    <input type="button" className="form-control btn btn-secondary" value="Now" onClick={() => {
                                        const now = new Date();
                                        formik.setFieldValue('unloadDate', now);
                                        formik.setFieldValue('unloadTime', now);
                                    }} disabled={formik.isSubmitting || formik.isValidating} />
                                </FormColumn>
                                <FormColumn columns={1}>
                                </FormColumn>
                                <FormColumn columns={3}>
                                    <InputField labelText="Door" name="unloadDoor" title={help.unloadDoor} required={true} maxLength={8} />
                                </FormColumn>
                            </FormRow>
                            <FormRow>
                                <FormColumn columns={12}>
                                    <DriverPickerField formik={formik} labelText="Dock worker" name="unloadDockWorker" title={help.unloadDockWorker} required={true} />
                                </FormColumn>
                            </FormRow>
                            <FormRow>
                                <FormColumn columns={12}>
                                    <TextAreaField labelText="Inbound notes / OS&amp;D" name="inboundNotes" title={help.inboundNotes} required={false} rows={3} maxLength={175} />
                                </FormColumn>
                            </FormRow>
                            <FormRow>
                                <FormColumn columns={12}>
                                    <InputField  labelText="Inbound driver name" name="driverNameInbound" title={help.driverNameInbound} required={true} maxLength={35} />
                                </FormColumn>
                            </FormRow>
                        </FormSection>

                    </FormColumn>

                    <FormColumn columns={6} className="my-1">
                        <FormSection title="Loading" className="mb-0">
                            <FormRow>
                            <FormColumn columns={3}>
                                    <DateField labelText="Load date" name="loadDate" title={help.loadDate} required={false} />
                                </FormColumn>
                                <FormColumn columns={3}>
                                    <TimeField labelText="Load time" name="loadTime" title={help.loadTime} required={false} />
                                </FormColumn>
                                <FormColumn columns={2}>
                                    <label>&nbsp;</label>
                                    <input type="button" className="form-control btn btn-secondary" value="Now" onClick={() => {
                                        const now = new Date();
                                        formik.setFieldValue('loadDate', now);
                                        formik.setFieldValue('loadTime', now);
                                    }} disabled={formik.isSubmitting || formik.isValidating} />
                                </FormColumn>
                                <FormColumn columns={1}>
                                </FormColumn>
                                <FormColumn columns={3}>
                                    <InputField labelText="Door" name="loadDoor" title={help.loadDoor} required={false} maxLength={8} />
                                </FormColumn>
                            </FormRow>
                            <FormRow>
                                <FormColumn columns={12}>
                                    <DriverPickerField formik={formik} labelText="Dock worker" name="loadDockWorker" title={help.loadDockWorker} required={false} />
                                </FormColumn>
                            </FormRow>
                            <FormRow>
                                <FormColumn columns={12}>
                                    <TextAreaField labelText="Outbound notes / OS&amp;D" name="outboundNotes" title={help.outboundNotes} required={false} rows={3} maxLength={175} />
                                </FormColumn>
                            </FormRow>
                            <FormRow>
                                <FormColumn columns={12}>
                                    <InputField labelText="Outbound driver name" name="driverNameOutbound" title={help.driverNameOutbound} required={false} maxLength={35} />
                                </FormColumn>
                            </FormRow>
                        </FormSection>
                    </FormColumn>
                </FormRow>


                <FormSection title="Cross-dock" className="mb-0">
                    <FormRow>
                        <FormColumn columns={12}>
                            <InputField labelText="Last placed" name="lastPlaced" title={help.lastPlaced} required={false} maxLength={56} />
                        </FormColumn>
                        <FormColumn columns={12}>
                            <TextAreaField labelText="Cross-dock notes" name="crossDockNotes" title={help.crossDockNotes} required={false} rows={3} maxLength={192} />
                        </FormColumn>
                    </FormRow>
                </FormSection>
                {this.renderButtonRow(formik)}

                {this.showLabelImage && this.state.labelPreview && 
                <FormSection title="Label" className="mb-0">
                    <FormRow>
                        <FormColumn columns={6}>
                            <LabelImage alt={'Cross-dock label'} labelzpl={this.state.labelPreview} labelnumber={0} rotate={0} />
                        </FormColumn>
                        <FormColumn columns={6}>
                            <LabelImage alt={'Freight label'} labelzpl={this.state.labelPreview} labelnumber={1} rotate={270} />
                        </FormColumn>
                    </FormRow>
                </FormSection>}

                </Form>
           }
           </Formik>
           </div>;
    }

    skeletonWorkOrderViewerLink(workOrderNumber: string) {
        return <a target="_blank" href={"http://qrosey.rosenaucorp.ca:10080/skeleton-workorder-80/index.php?pro=" + workOrderNumber}>{workOrderNumber}</a>;
    }

    normalizePostalCode(postalCode: string): string {
        if (!postalCode) return '';

        postalCode = postalCode.trim().toUpperCase();

        const usRegExp = RegExp(/^(\d{5})[ -]?(\d{4})?$/i);   // 5 digits, an optional dash or space separator and 4 optional additional digits. The 1st capturing group is the first 5 digits the 2nd are the last 4 digits.
        const caRegExp = RegExp(/^([A-Z]\d[A-Z])[ -]?(\d[A-Z]\d)$/i); // A9A 9A9 with an optional space or dash separator in between. The 1st capturing group is the first 3 chars, the 2nd are the last three chars.

        // Add a space inside a Canadian postal code
        const caResult = caRegExp.exec(postalCode);
        if (caResult) {
            return caResult[1] + ' ' + caResult[2];
        }

        // Add a dash inside the US postal code if the minor code is specified
        const usResult = usRegExp.exec(postalCode);
        if (usResult) {
            return usResult[1] + ( (usResult[2]) ? '-' + usResult[2] : '' );
        }
        
        // return the uppercased trimmed input if neither US nor Canadian
        return postalCode;

    }

    // Perform this ritual to turn the WorkOrder object into a DTO that plays nice with NewtonSoft.JSON in .NET
    fixValues(values: WorkOrder) {

        values.label = "";

        // standardize the postal code format
        values.shipperPostalCode = this.normalizePostalCode(values.shipperPostalCode);
        values.consigneePostalCode = this.normalizePostalCode(values.consigneePostalCode);

    }

    async submit(values: WorkOrder, formikHelpers: FormikHelpers<WorkOrder>) {
        if (!this.state.errorMessage) {
            try {

                // do some in-place updates to make our values friendly
                this.fixValues(values);

                //
                // API calls:
                //

                if (this.useBackEnd) {
                    if (this.isTest) console.log("Saving work order " + values.guid);

                    // Get the AD JWT token:
                    const idTokenResponse = await authProvider.getIdToken();
    
                    // Save the work order:
                    var response = await Axios.post<SaveWorkOrderResponse>(process.env.REACT_APP_LabelPrinterEndpointBaseURL + '/order/save', withoutEmpty(values), {
                        headers: {
                            "Authorization": "Bearer " + idTokenResponse.idToken.rawIdToken
                        }
                    });
    
                    values.workOrderNumber = response.data.workOrderNumber;
                    values.manifestNumber = response.data.manifestNumber;
    
                    if (this.isTest) console.log("Saved work order " + values.guid + " WorkOrderNumber " + values.workOrderNumber + " ManifestNumber " + values.manifestNumber);
    
                } else {
                    values.workOrderNumber = (880000000 + Math.floor(Math.random()*10000)).toString();
                    values.manifestNumber = "n/a";
                    if (this.isTest) console.log("Back-end not used, generated random WorkOrderNumber " + values.workOrderNumber);
                }

                //
                // Print the label
                //

                if (this.usePrinter) {

                    if (this.isTest) console.log("Preparing to print label.");
                
                    const device = await this.getZebraDevice();
    
                    if (device) {
                        
                        if (this.isTest) console.log("Device found. Creating label ZPL.");
                        var label = createZebraLabelForPrinting(values);
    
                        if (this.isTest) console.log("Label created. Start printing.");
                        const printed = await this.printLabel(device, label);
    
                        if (printed) {
                            if (this.isTest) console.log("Label printed.");
                        }
    
                    }
                } else {
                    if (this.isTest) console.log("Printing skipped.");
                }

                //
                // Reset form
                //
                this.setState({
                    formComplete: true,
                    formCompleteMessage: <React.Fragment>Your work order has been saved and your label has been printed. Work order # <strong>{this.skeletonWorkOrderViewerLink(values.workOrderNumber)}</strong>, manifest number <strong>{values.manifestNumber}</strong>.</React.Fragment>,
                    formValidated: false,
                    errorMessage: null,
                    previousWorkorder: cloneWorkOrder(values)
                });
                if (this.clearFormAfterSubmit) {
                    formikHelpers.resetForm();
                }

                //
                // Generate label preview
                //
                if (this.showLabelImage) {
                    this.setState({labelPreview: createZebraLabelForDisplay(values)})                    
                } else {
                    this.setState({labelPreview: ''})                    
                }

            } catch (error) {
                this.setState({
                    formCompleteMessage: null,
                    formComplete: false,
                    errorMessage: <React.Fragment>Unexpected error submitting the form: <strong>{getErrorMessage(error)}</strong></React.Fragment>,
                    formValidated: true
                });

            }
        }

        return Promise.resolve();
    }

    async validate(values: WorkOrder) {

        if (this.isTest) console.log("Validating " + values.guid);
        
        this.setState({
            errorMessage: null,
            formCompleteMessage: null,
            formComplete: false
        });

        try {

            // do some in-place updates to make our values friendly
            this.fixValues(values);

            //
            // Call API to validate if useBackEnd is true
            //

            if (this.useBackEnd) {

                // Get the JWT token
                const idTokenResponse = await authProvider.getIdToken();

                // Call the validate endpoint
                const response = await Axios.post(process.env.REACT_APP_LabelPrinterEndpointBaseURL + '/order/validate', withoutEmpty(values), {
                    headers: {
                        "Authorization": "Bearer " + idTokenResponse.idToken.rawIdToken
                    }
                });
                
                // Convert API results to Formik errors
                const errors = getFormikErrorsFromValidationResult(response.data);

                if (this.isTest) console.log('Validation errors:');
                if (this.isTest) console.log(errors);

                this.setState({
                    formValidated: true
                });
                return Promise.resolve(errors);

            } else {

                this.setState({
                    formValidated: true
                });
                return Promise.resolve({});

            }

        } catch (error) {
            this.setState({
                errorMessage: <React.Fragment>Unexpected error submitting the form: <strong>{getErrorMessage(error)}</strong></React.Fragment>,
                formValidated: true
            });

            return Promise.resolve({});
        }
    }

    private getZebraDevice(): Promise<any> {
        return new Promise(resolve => {

            BrowserPrint.getDefaultDevice("printer", (device: any) => {
                if (device) {
                    resolve(device);
                } else {
                    this.setState({
                        errorMessage: <React.Fragment>Cannot print label: Unable to connect to Zebra printer.</React.Fragment>
                    });

                    resolve();
                }
            }, (error: any) => {
                this.setState({
                    errorMessage: <React.Fragment>Cannot print label: {error || "Unable to connect to Zebra printer."}</React.Fragment>
                });

                resolve();
            });
        });
    }

    private printLabel(device: any, label: string): Promise<boolean> {
        return new Promise(resolve => {
            device.send(label, () => {
                resolve(true);
            }, (error: any) => {
                this.setState({
                    errorMessage: <React.Fragment>Unexpected error printing the label: {error || "Unknown error."}</React.Fragment>
                });
    
                resolve(false);
            });
        });
    }

}