import React from 'react';
import axios from "axios";

//
// ZPL label viewer, renders a PNG image of the label via api.labelary.com.
// Supports multi-page labels and rotation.
// Renders the label ZPL code in the DOM for debugging.
//

interface LabelImageProps {
    labelzpl: string;
    labelnumber: number;
    rotate: 0 | 90 | 180 | 270;
    alt: string;
}

interface LabelImageState {
    isFetching: boolean;
    imageURL: string;
}

export default class LabelImage extends React.Component<LabelImageProps & React.HTMLAttributes<HTMLElement>, LabelImageState>  {

    constructor(props: Readonly<LabelImageProps>) {
        super(props);

        this.state = {
            isFetching: false,
            imageURL: ""
        };

    }

    public componentDidMount() {

        // indicate fetching
        this.setState({isFetching: true, imageURL: ""});

        // Get label image
        this.getImage(
            this.props.labelzpl,
            this.props.labelnumber,
            this.props.rotate,
            (response) => this.setState({...this.state, isFetching: false, imageURL: URL.createObjectURL(response.data)})
        );
    }

    public componentWillUnmount() {
        URL.revokeObjectURL(this.state.imageURL);
    }

    render() {
        const { className, alt, ...rest } = this.props;
        return (
            <div>
                {(this.state.isFetching) && <span>Loading...</span>}
                {(this.state.imageURL) && <img className={this.getClassName(className)} onClick={this.printImage.bind(this)} alt={alt} src={this.state.imageURL} {...rest} />}
            </div>
        );
    }

    printImage() {
        const Pagelink = "about:blank";
        const pwa = window.open(Pagelink, "_new");
        pwa?.document.open();
        pwa?.document.write("<html><head><script>function step1(){\n" +
            "setTimeout('step2()', 10);}\n" +
            "function step2(){window.print();window.close()}\n" +
            "</script></head><body onload='step1()'>\n" +
            "<img src='" + this.state.imageURL + "' /></body></html>");
        pwa?.document.close();
    }

    getClassName(className?: string) {
        let result = "border shadow rounded bg-white img-fluid";

        if (className) {
            return result + className;
        } else {
            return result;
        }
    }

    getImage(labelData: string, labelNumber: number, rotation: number, onSuccess: (response) => void, onError: (error) => void = (_) => {}, retryCount: number = 0) {
        const maxRetryCount = 3;
        const retryInterval = 5000 * (1 + retryCount); // milliseconds

        axios.post(
            `http://api.labelary.com/v1/printers/8dpmm/labels/4x6/${labelNumber}/`,
            labelData, 
            {
                headers: { 
                    'Accept': 'image/png',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'X-Rotation': rotation
                },
                responseType: 'blob'
            }
        )
        .then(onSuccess)
        .catch((error) => {
            if (retryCount >= maxRetryCount) {
                console.log(error);
                console.log("Max retry count reached, aborting.");
                // max retry reached, call the top level error handler
                if (onError) onError(error);
            } else {
                console.log(error);
                console.log(`Retrying after ${retryInterval} ms, retry count ${retryCount + 1} of ${maxRetryCount}...`);
                setTimeout(() => { 
                    this.getImage(labelData, labelNumber, rotation, onSuccess, onError, retryCount + 1) 
                }, retryInterval);
            }

        });
    }

}
