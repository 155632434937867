import React from 'react';
import { FieldInputProps, FieldMetaProps } from 'formik';
import { FormField, FormFieldProps } from '@rosenau/rosenau-ui';

/*
    A special numeric input field that renders its label to the right of the input box.
    Used for rendering value added service inputs.
    Rationale: these were checkboxes originally on both the printed form and the UI. We changed them to numeric inputs but kept the checkbox-like visuals.
*/

export default class FormValueAddedServiceInputField<V> extends FormField<V, FormFieldProps & React.InputHTMLAttributes<HTMLInputElement>> {
    renderInput(field: FieldInputProps<V>, meta: FieldMetaProps<V>): JSX.Element {
        const { className, name, labelText, helpText, fieldColumns, fieldColumnBreakpoint, inputGroupPrepend, inputGroupAppend, onChange, ...rest } = this.props;
        var _onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

        if (onChange) {
            _onChange = event => {
                field.onChange(event);
                onChange(event);
            };
        } else {
            _onChange = field.onChange;
        }

        return (
            // Should not have to cast field.value to any, see https://github.com/jaredpalmer/formik/issues/1983
            // had to use "or blank" in the input value, otherwise null/undefined would not clear the input upon reset.
            <div className="form-group row">
                <div className="col-sm-4">
                    <input className={this.getClassName(meta, className)} type="number" value={field.value as any || ''} onChange={_onChange} onBlur={field.onBlur} id={field.name} name={field.name} {...rest} />
                </div>   
                <label className="col-sm-8 col-form-label" htmlFor={field.name} style={{fontWeight: "normal"}}>{labelText}</label>
            </div>
        );
    }

    shouldRenderLabel() {
        return false;
    }

    getClassName(meta: FieldMetaProps<V>, className?: string): string {
        let result: string;

        if (meta.error) {
            result = "form-control is-invalid";
        } else {
            result = "form-control";
        }

        if (className) {
            return result + " " + className;
        } else {
            return result;
        }
    }
}
