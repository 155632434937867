import React from 'react';
import { FieldInputProps, FieldMetaProps } from 'formik';
import { FormCheckboxField } from '@rosenau/rosenau-ui';

/*
    Special checkbox that renders a red asterisk after the label if the checkbox is checked.
    Used to render the "Other services" checkbox, indicating that the next field (the other services text area) is required. 
*/

export default class FormRequiredIfCheckedCheckboxField<V> extends FormCheckboxField<V> {
    renderInput(field: FieldInputProps<V>, meta: FieldMetaProps<V>): JSX.Element {
        const { className, name, labelText, helpText, fieldColumns, fieldColumnBreakpoint, inputGroupPrepend, inputGroupAppend, onChange, ...rest } = this.props;
        var _onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

        if (onChange) {
            _onChange = event => {
                field.onChange(event);
                onChange(event);
            };
        } else {
            _onChange = field.onChange;
        }

        return (
            // Should not have to cast field.value to any, see https://github.com/jaredpalmer/formik/issues/1983
            <div className="form-group row">
                <div className="col-sm-4">
                    <input className={this.getClassName(meta, className)} type="checkbox" value="true" checked={field.value as any} onChange={_onChange} onBlur={field.onBlur} id={field.name} name={field.name} {...rest} />
                </div>
                <label className="col-sm-8 col-form-label" htmlFor={field.name} style={{fontWeight: "normal"}}>{labelText}{field.value && <span className="d-inline-block ml-1 text-danger" title={`${this.props.labelText} is required.`}>*</span>}</label>
            </div>
        );

    }

    getClassName(meta: FieldMetaProps<V>, className?: string): string {
        let result: string = "form-control shadow-none w-25 float-right";

        if (meta.error) {
            result += " is-invalid";
        }

        if (className) {
            return result + " " + className;
        } else {
            return result;
        }
    }


}
