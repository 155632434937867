import React from 'react';
import { FieldInputProps, FieldMetaProps } from 'formik';
import { FormCheckboxField } from '@rosenau/rosenau-ui';

/*
    A special numeric input field that renders its label to the right of the input box.
    Used for rendering value added service inputs.
    Rationale: these were checkboxes originally on both the printed form and the UI. We changed them to numeric inputs but kept the checkbox-like visuals.
*/

export default class FormValueAddedServiceCheckBoxField<V> extends FormCheckboxField<V> {
    renderInput(field: FieldInputProps<V>, meta: FieldMetaProps<V>): JSX.Element {
        const { className, name, labelText, helpText, fieldColumns, fieldColumnBreakpoint, inputGroupPrepend, inputGroupAppend, onChange, ...rest } = this.props;
        var _onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

        if (onChange) {
            _onChange = event => {
                field.onChange(event);
                onChange(event);
            };
        } else {
            _onChange = field.onChange;
        }

        return (
            // Should not have to cast field.value to any, see https://github.com/jaredpalmer/formik/issues/1983
            // This input expects a numeric input field and casts 0 as false, 1 as true.
            <div className="form-group row">
                <div className="col-sm-4">
                    <input className={this.getClassName(meta, className)} type="checkbox" value="true" checked={(field.value as any)} onChange={_onChange} onBlur={field.onBlur} id={field.name} name={field.name} {...rest} />
                </div>
                <label className="col-sm-8 col-form-label" htmlFor={field.name} style={{fontWeight: "normal"}}>{labelText}</label>
            </div>
        );
    }

    shouldBeChecked() {

    }
    shouldRenderLabel() {
        return false;
    }

    getClassName(meta: FieldMetaProps<V>, className?: string): string {
        let result: string = "form-control shadow-none w-25 float-right";

        if (meta.error) {
            result += " is-invalid";
        }

        if (className) {
            return result + " " + className;
        } else {
            return result;
        }
    }
}
